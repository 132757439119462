<template>
  <span v-if="useRoute().name != 'index' && useRoute().name != 'event-live' && useRoute().name != 'event-urut'">
    <q-layout view="hHh Lpr lff">
      <q-header elevated class="header">
        <q-toolbar class="text-primary">
          <q-btn
            v-if="!drawer && routeName != 'index'"
            flat
            @click="drawer = !drawer"
            round
            dense
            icon="menu"
            color="white"
            class="btn-burger"
          />
          <q-toolbar-title class="txt-title-header">{{
            titleLayout
          }}</q-toolbar-title>
          <q-avatar v-if="routeName != 'index'" style="cursor: pointer">
            <img src="~/assets/img/avatar.png" />
            <q-menu>
              <q-list dense style="min-width: 100px">
                <q-item
                  clickable
                  @click="
                    $router.push({
                      path: '/pengguna/profile'
                    })
                  "
                >
                  <q-item-section> Pofile </q-item-section>
                  <q-item-section side>
                    <i class="pi pi-user"></i>
                  </q-item-section>
                </q-item>
                <q-item @click="logout" clickable>
                  <q-item-section> Logout </q-item-section>
                  <q-item-section side>
                    <i class="pi pi-power-off"></i>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-menu>
          </q-avatar>

          <q-btn
            v-if="!drawer && routeName === 'index'"
            flat
            @click="drawer = !drawer"
            round
            dense
            icon="menu"
            color="white"
            class="btn-burger-menu"
          />
        </q-toolbar>
      </q-header>
      <q-drawer v-model="drawer" show-if-above :width="300" bordered>
        <q-scroll-area class="fit">
          <div class="sidemenu">
            <p class="txt-title">Buku Tamu Digital</p>
            <p class="txt-title-content-menu">{{ dataUser.name }}</p>
            <p class="txt-subtitle">{{ dataUser.role }}</p>
            <div class="row">
              <div class="col-12" style="margin-right: 5px">
                <p class="txt-title-forms-head">Daftar Menu</p>

                <ul class="sub-menu">
                  <li
                    @click="
                      $router.push({
                        path: '/event'
                      })
                    "
                    style="display: flex; align-items: center; cursor: pointer"
                    :class="[
                      (routeName == 'event') | (routeName == 'guest')
                        ? 'active'
                        : ''
                    ]"
                  >
                    <i class="pi pi-folder"></i>
                    <span style="margin-left: 12px; margin-top: 5px">
                      Master Event
                    </span>
                  </li>

                  <li
                    @click="
                      $router.push({
                        path: '/pengguna'
                      })
                    "
                    style="display: flex; align-items: center; cursor: pointer"
                    :class="[routeName == 'pengguna' ? 'active' : '']"
                  >
                    <i class="pi pi-user"></i>

                    <span style="margin-left: 12px; margin-top: 5px">
                      Master Pengguna
                    </span>
                  </li>

                  <li
                    @click="
                      $router.push({
                        path: '/event/laporan'
                      })
                    "
                    style="display: flex; align-items: center; cursor: pointer"
                    :class="[(routeName == 'event-laporan') | (routeName == 'laporan-kehadiran') ? 'active' : '']"
                  >
                    <i class="pi pi-book"></i>
                    <span style="margin-left: 12px; margin-top: 5px">
                      Laporan Event
                    </span>
                  </li>
                  <li
                    @click="logout"
                    style="display: flex; align-items: center; cursor: pointer"
                    :class="[routeName == 'logout' ? 'active' : '']"
                  >
                    <i class="pi pi-power-off"></i>
                    <span style="margin-left: 12px; margin-top: 5px">
                      Logout
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <span class="txt-footer">
            Copyright &copy; Guestifa Indonesia
            <span class="pi pi-heart-fill text-danger"></span>
          </span>
        </q-scroll-area>
      </q-drawer>

      <q-page-container>
        <q-page>
          <NuxtPage />
        </q-page>
      </q-page-container>
    </q-layout>
  </span>

  <span v-else>
    <q-layout>
      <q-page-container>
        <q-page>
          <NuxtPage />
        </q-page>
      </q-page-container>
    </q-layout>
  </span>
</template>

<script>
import { useRoute } from "vue-router";
import { useMainStore } from "@/store/index";

export default {
  middleware: "auth",
  data() {
    return {
      drawer: false,
      dataSidebar: {}
    };
  },
  setup() {
    const store = useMainStore();

    return {
      store
    };
  },
  mounted() {
    if (useRoute().name != "index") {
      const loader = useNuxtApp().$loading.show();
      const accessToken = localStorage.getItem("accessToken");
      this.store
        .getSidebar(accessToken)
        .then((result) => {
          this.store
            .dataAccount(result.data.data.idUser)
            .then((response) => {
              loader.hide();
            })
            .catch((error) => {
              useNuxtApp().$toast.error(error.response.data.message, {
                theme: "colored",
                position: "top-center"
              });
              loader.hide();
            });
        })
        .catch((error) => {
          useNuxtApp().$toast.error(error.response.data.message, {
            theme: "colored",
            position: "top-center"
          });
          loader.hide();
        });
    }

    // window.location.reload()
  },
  methods: {
    logout() {
      const loader = useNuxtApp().$loading.show();
      this.store
        .logout()
        .then((result) => {
          useNuxtApp().$toast.success("Anda berhasil logout!", {
            theme: "colored",
            position: "top-center"
          });
          loader.hide();

          localStorage.removeItem("isLoged");
          setTimeout(() => {
            this.$router.push({ path: "/" });
          }, 1000);
        })
        .catch((error) => {
          useNuxtApp().$toast.error(error.response.data.message, {
            theme: "colored",
            position: "top-center"
          });
          loader.hide();
        });
    },
    capitalising: function (data) {
      const capitalized = [];
      data.split(" ").forEach((word) => {
        capitalized.push(
          word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
        );
      });
      return capitalized.join(" ");
    }
  },
  computed: {
    routeName() {
      return useRoute().name;
    },
    titleLayout() {
      if (this.routeName == "index") {
        return "Buku Tamu Digital";
      } else if (this.routeName == "event") {
        return "Master Event";
      } else if (this.routeName == "guest") {
        return "Data Guest";
      } else if (this.routeName == "pengguna") {
        return "Master Pengguna";
      } else if (this.routeName == "event-laporan") {
        return "Laporan Event";
      } else if (this.routeName == "laporan-kehadiran") {
        return "Laporan Kehadiran";
      } else if (this.routeName == "pengguna-profile") {
        return "Profile User";
      }
    },
    dataUser() {
      return this.store.dataUser;
    }
  }
};
</script>
